<template>
	<div>
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Statement / Report</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item active>Statement / Report </b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
				
			</b-col>
		</b-row>
		<br>
		<b-tabs>
			
			
			<b-tab  lazy  :active="url_name=='/Admin/satement_report'"  @click="$router.push('/Admin/satement_report')"  >
				<template #title>
					<strong>Revenue Report </strong>  
				</template>
				<transition name="fade-transform" mode="out-in">
					<router-view/>
				</transition>
			<!-- 	v-if="permision_access[23].vb_view==1"
				v-if="permision_access[24].vb_view==1"
				v-if="permision_access[25].vb_view==1"
				v-if="permision_access[26].vb_view==1" -->
			</b-tab> 
			<b-tab lazy :active="url_name=='/Finance/satement_report/income-expense'"  @click="$router.push('/Finance/satement_report/income-expense')">
				<template #title  >
					<strong>Income & Expense Report</strong>  
				</template>
				<transition name="fade-transform" mode="out-in">
					<router-view/>
				</transition>
			</b-tab>
			
			<b-tab lazy :active="url_name=='/Finance/satement_report/recept-payment'"  @click="$router.push('/Finance/satement_report/recept-payment')">
				<template #title  >
					<strong>Receipt & Payment Report</strong>  
				</template>
				<transition name="fade-transform" mode="out-in">
					<router-view/>
				</transition>
			</b-tab> 
			
			<b-tab lazy  :active="url_name=='/Finance/satement_report/vb_report'"  @click="$router.push('/Finance/satement_report/vb_report')">
				<template #title  >
					<strong>VB Report</strong>  
				</template>
				<transition name="fade-transform" mode="out-in">
					<router-view/>
				</transition>
			</b-tab> 
			
			
			
		</b-tabs>
	</div>
</template>

<script>
import axios from 'axios'
/* import IncomeExpense from './income_expense.vue'
import ReceiptPayment from './receipt_payment.vue'
import vbReport from './vb_report.vue'
import Revenue from './revenue.vue' */
	export default {

		components:
		{
			/* IncomeExpense,
			ReceiptPayment,
			vbReport,
			Revenue */
		},
		data: () => ({
			permision_access:'',
			url_name:'',
		}),
		methods:
		{
			access_permission()
			{
				axios
					.post("/api/access_permission_view").then((resp) => {
						this.permision_access = resp.data;
					});
			}
		},
		mounted()
		{
			this.access_permission();
			this.url_name = window.location.pathname;
		},
	}
</script>

<style>
	.border-right
	{
		border-right:10px solid #e9ecef !important;
	}
</style>